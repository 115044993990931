import { motion } from "framer-motion";

const Arrow = () => {
    const pathLength = 530; // Set this to the length of your path
    return (
        <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="113"
            height="100%"
            viewBox="0 0 113 117"
            fill="none"
        >
            <motion.path
                d="M90.586 23.0053L101.841 10.5722L87.5737 1.70596"
                stroke="#FFF7F3"
                stroke-width="3"
                strokeDasharray={pathLength}
                strokeDashoffset={pathLength} // Start with the full length offset
                initial={{ opacity: 0 }}
                animate={{ strokeDashoffset: 0, opacity: 1 }} // Animate to 0 to draw the path
                transition={{
                    duration: 1,
                    ease: "easeInOut",
                    delay: 2.5,
                }}
            />
            <motion.path
                d="M12.7642 115.992C4.40168 46.5436 45.3733 18.403 99.9773 10.7461"
                stroke="#FFF7F3"
                stroke-width="3"
                strokeDasharray={pathLength}
                strokeDashoffset={pathLength} // Start with the full length offset
                initial={{ opacity: 0 }}
                animate={{ strokeDashoffset: 0, opacity: 1 }} // Animate to 0 to draw the path
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    delay: 2,
                }}
            />
        </motion.svg>
    );
};

export default Arrow;
