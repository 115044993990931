import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./news.scss";
import { Article } from "../../types/article";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { formatDateWithOrdinal } from "../../helpers/helpers";

const News = () => {
    const [posts, setPosts] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(useGSAP);

    useEffect(() => {
        const elements = document.querySelectorAll(".animateIn");
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                {
                    opacity: 0,
                    y: 100,
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "bottom 20%",
                        toggleActions: "play none none reverse",
                    },
                }
            );
        });
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(
                    "https://wp.sirendigital.uk/mill-yard/wp-json/wp/v2/posts?_embed"
                );
                setPosts(response.data);
            } catch (err) {
                setError("Failed to load posts");
            } finally {
                setLoading(false);
            }
        };
        fetchPosts();
    }, []);

    return (
        <div className="news-hero contain-wrapper">
            {!loading && (
                <div className="news-page contain-two">
                    <h1 className="animateIn">THE LATEST</h1>
                    <ul>
                        {posts.map((post, index) => {
                            return index == 0 ? (
                                <div className="intro animateIn">
                                    <li key={post.id}>
                                        <div className="copy-wrapper">
                                            <div className="copy-flex">
                                                <h2>{post.title.rendered}</h2>
                                                <p
                                                    className="copy"
                                                    dangerouslySetInnerHTML={{
                                                        __html: post.excerpt
                                                            .rendered,
                                                    }}
                                                />
                                                <p className="date">
                                                    {formatDateWithOrdinal(
                                                        post.date
                                                    )}
                                                </p>
                                            </div>
                                            <Link to={`/news/${post.slug}`}>
                                                <div className="read-more">
                                                    <p>Read more</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div
                                            className="background-image"
                                            style={{
                                                backgroundImage: `url(${post._embedded["wp:featuredmedia"][0].source_url})`,
                                            }}
                                        />
                                    </li>
                                </div>
                            ) : (
                                <li key={post.id} className="animateIn">
                                    <div className="flex-top">
                                    <div
                                        className="background-image"
                                        style={{
                                            backgroundImage: `url(${post._embedded["wp:featuredmedia"][0].source_url})`,
                                        }}
                                    />
                                    <Link to={`/news/${post.slug}`}>
                                        <h2>{post.title.rendered}</h2>
                                    </Link>
                                    <p>{formatDateWithOrdinal(post.date)}</p>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: post.excerpt.rendered,
                                        }}
                                    />
                                    </div>
                                    <Link to={`/news/${post.slug}`}>
                                        <div className="read-more">
                                            <p>Read more</p>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default News;
