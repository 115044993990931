import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/home";
import './scss/global.scss';
import Nav from "./components/nav/nav";
import Footer from "./components/footer/footer";
import News from "./pages/news/news";
import NewsArticle from "./pages/newsArticle/newsArticle";
import Video from "./pages/videos/video";

const App: React.FC = () => {
    const routes = [
      { path: "/", component: Home },      
      { path: "/video", component: Video },
      { path: "/news", component: News },      
      { path:"/news/:slug", component: NewsArticle }
    ];

    return (
        <>
            <BrowserRouter>                           
                <Nav />
                <Routes>
                    {routes.map((route, index) => {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={<route.component />}
                            />
                        );
                    })}
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
};

export default App;
