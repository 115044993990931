import "./video.scss";

const Video = () => {
    return (
        <div className="contain-wrapper">
            <div className="video contain-two">
                <video controls>
                    <source
                        src="https://player.vimeo.com/progressive_redirect/playback/1033164597/rendition/1080p/file.mp4?loc=external&signature=3d39eb6a62b8a8447ddf925791eaf37ad186eb0d472eb34124575d066d086584"
                        type="video/mp4"
                    />
                </video>
            </div>
        </div>
    );
};

export default Video;
