import { useEffect, useState } from "react";
import "./footer.scss";
import { Input } from "@mui/base/Input";
import Logo from "../../components/logo/logo";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Footer: React.FC = () => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    return (
        <>
            <footer>
                <div className="contain-wrapper">
                    <div className="contain footer-wrapper">
                        <Logo />
                        <p className="title animateInFooter">
                            Railpen and Socius are committed to Cambridge and
                            the local community, and delivering a development of
                            which the city can be proud. Sign up below to
                            receive a quarterly newsletter on the progress of
                            the project. In the meantime, if you have any
                            questions, please contact the team via{" "}
                            <a href="mailto:info@millyardcambridge.com">
                                info@millyardcambridge.com
                            </a>
                        </p>
                        <div className="newsletter animateInFooter">
                            <form
                                action="https://sirendesign.us1.list-manage.com/subscribe/post?u=20d2469644d92877e262b7d08&amp;id=66177fe778&amp;f_id=003170e2f0"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                className="validate"
                                target="_blank"
                            >
                                <div className="input-wrapper">
                                    {/* Bind the input value to the first name */}
                                    <input
                                        type="text"
                                        name="FNAME"
                                        className="text base-Input-input"
                                        id="mce-FNAME"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="input-wrapper">
                                    {/* Bind the input value to the email */}
                                    <input
                                        type="email"
                                        name="EMAIL"
                                        className="required email base-Input-input"
                                        id="mce-EMAIL"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                                <div className="btn-wrapper">
                                    <button
                                        type="submit"
                                        id="mc-embedded-subscribe"
                                        className="button"
                                    >
                                        <p>submit</p>
                                    </button>
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "-5000px",
                                    }}
                                    aria-hidden="true"
                                >
                                    <input
                                        type="text"
                                        name="b_20d2469644d92877e262b7d08_66177fe778"
                                        value=""
                                    />
                                </div>
                                <div id="mce-responses" className="clear">
                                    <div
                                        className="response"
                                        id="mce-error-response"
                                        style={{ display: "none" }}
                                    ></div>
                                    <div
                                        className="response"
                                        id="mce-success-response"
                                        style={{ display: "none" }}
                                    ></div>
                                </div>
                            </form>
                        </div>

                        <div className="contacts">
                            <h3 className="animateInFooter">
                                Contact agents for workspace enquiries
                            </h3>
                            <div className="agents-hero">
                                <div className="agents animateInFooter">
                                    <img src="../images/bidwells.svg" />
                                    <div className="agent">
                                        <p>
                                            <b>Dick Wise</b>
                                        </p>
                                        <a href="mailto:dick.wise@bidwells.co.uk">
                                            <p>dick.wise@bidwells.co.uk</p>
                                        </a>
                                        <a href="mailto:07879 667 543">
                                            <p>07879 667 543</p>
                                        </a>
                                    </div>
                                    <div className="agent">
                                        <p>
                                            <b>Max Bryan</b>
                                        </p>
                                        <a href="mailto:max.bryan@bidwells.co.uk">
                                            <p>max.bryan@bidwells.co.uk</p>
                                        </a>
                                        <a href="mailto:07879 667 543">
                                            <p>07879 667 543</p>
                                        </a>
                                    </div>
                                    {/* <div className="agent">
                                        <p>
                                            <b>George Craig</b>
                                        </p>
                                        <p>george.craig@bidwells.co.uk</p>
                                    </div> */}
                                </div>
                                <div className="agents animateInFooter">
                                    <img src="../images/jll.svg" />
                                    <div className="agent">
                                        <p>
                                            <b>Ollie Mcleod</b>
                                        </p>
                                        <a href="mailto:ollie.mcleod@eu.jll.com">
                                            <p>ollie.mcleod@eu.jll.com</p>
                                        </a>
                                        <a href="tel:07790 562 040">
                                            <p>07790 562 040</p>
                                        </a>
                                    </div>
                                    <div className="agent">
                                        <p>
                                            <b>Edward Hoyle</b>
                                        </p>
                                        <a href="edward.hoyle@jll.com">
                                            <p>edward.hoyle@jll.com</p>
                                        </a>
                                        <a href="tel:07790 562 040">
                                            <p>07790 562 040</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="agents animateInFooter">
                                    <img src="../images/cushman.svg" />
                                    <div className="agent">
                                        <p>
                                            <b>James Goodwin</b>
                                        </p>
                                        <a href="mailot:james.goodwin@cushwake.com">
                                            <p>james.goodwin@cushwake.com</p>
                                        </a>
                                        <a href="tel:07717 652 803">
                                            <p>07717 652 803</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="dev-wrapper">
                                <div className="development animateInFooter">
                                    <h3>A development by</h3>
                                    <div className="dev">
                                        <img src="../images/railpen.svg" />
                                        <img src="../images/socius.svg" />
                                    </div>
                                </div>
                                <div className="development animateInFooter">
                                    <h3>Follow us</h3>
                                    <div className="dev">
                                        <a target="_blank" href="https://www.instagram.com/millyardcambridge/">
                                            <img src="../images/insta.svg" />
                                        </a>
                                        <a target="_blank" href="https://www.linkedin.com/company/millyardcambridge/posts/?feedView=all">
                                            <img src="../images/linkedin.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            <p>©2024. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
