import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    // You can adjust the breakpoint as needed
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize); // Check on resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
