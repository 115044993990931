import { useEffect, useState } from 'react';

function useOutOfViewOnce(ref: React.RefObject<HTMLElement>): boolean {
  const [hasGoneOutOfView, setHasGoneOutOfView] = useState(false);

  useEffect(() => {
    if (!ref.current || hasGoneOutOfView) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && !hasGoneOutOfView) {
            setHasGoneOutOfView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.8 }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, hasGoneOutOfView]);

  return hasGoneOutOfView;
}

export default useOutOfViewOnce;
