import { Link, useLocation } from "react-router-dom";
import "./nav.scss";
import { useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { animate, AnimatePresence, motion } from "framer-motion";
import { height } from "@mui/system";

const Nav = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const location = useLocation();
    gsap.registerPlugin(useGSAP);

    const container = useRef<HTMLDivElement | null>(null);
    const tl = useRef<gsap.core.Timeline | null>(null);

    const toggleTimeline = () => {
        if (tl.current) {
            tl.current.reversed(!tl.current.reversed());
        }
    };
    useGSAP(
        () => {
            const lines = gsap.utils.toArray<HTMLElement>(".line");
            tl.current = gsap
                .timeline()
                .to(lines[0], { marginLeft: 0, duration: 0.25 }, "<")
                .to(lines[1], { marginLeft: 0, duration: 0.25 }, "<")
                .to(lines[2], { marginLeft: 0, duration: 0.25 }, "<")
                .reverse();
        },
        { scope: container }
    );

    const burgerContainer = useRef<HTMLDivElement | null>(null);
    const burger = useRef<HTMLDivElement | null>(null);
    const tl2 = useRef<gsap.core.Timeline | null>(null);

    const toggleNavTimeline = () => {
        if (tl2.current) {
            tl2.current.reversed(!tl2.current.reversed());
        }
    };

    // useGSAP(
    //     () => {
    //         tl2.current = gsap
    //             .timeline({ paused: true })
    //             .to(burger.current, { duration: 0.5, x: 0 })
    //             .to(burgerContainer.current, { duration: 0.5, x: 0 }, "<")
    //             .to(
    //                 burgerContainer.current,
    //                 { duration: 0.5, display: "block" },
    //                 "<"
    //             )
    //             .reverse();
    //     },
    //     {
    //         scope: burgerContainer,
    //     }
    // );


    const tl3 = useRef<gsap.core.Timeline | null>(null);
    useGSAP(
        () => {
            const lines = gsap.utils.toArray<HTMLElement>(".line");
            tl3.current = gsap
                .timeline({ paused: true })
                .to(lines[0], { duration: 0.5, backgroundColor: '#FF5E5E', y: 7.5, height: "3px" }, '<')
                .to(lines[1], { duration: 0.5, backgroundColor: '#FF5E5E', y: 0, height: "3px" }, '<')
                .to(lines[2], { duration: 0.5, backgroundColor: '#FF5E5E', y: -7.5, height: "3px"  }, '<')
                .to(lines[3], { duration: 0.5, backgroundColor: '#FF5E5E', display: 'flex', y: -3, height: "3px"  }, '<')                                
                .to(lines[0], { duration: 0.15, rotate: '90deg'  })            
                .to(lines[1], { duration: 0.15, rotate: '45deg', stagger: 0.1  }, '<')            
                .to(lines[2], { duration: 0.15, rotate: '135deg', stagger: 0.1  }, '<')            
                .to(lines[3], { duration: 0.15, rotate: '0', display: 'flex', stagger: 0.1   }, '<')                  
                .reverse();
        },
        {
            scope: container,
        }
    );

    const toggleStarTimeline = () => {
        if (tl3.current) {
            tl3.current.reversed(!tl3.current.reversed());
        }
    }
    

    return (
        <>
            <div
                className="burger"
                onMouseEnter={() => { if (!isOpen) toggleTimeline(); }}
                onMouseLeave={() => { if (!isOpen) toggleTimeline(); }}
                onClick={() => {
                    setIsOpen(!isOpen);
                    toggleNavTimeline();
                    toggleStarTimeline();
                }}
                ref={container}
            >
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="circle"></div>
            </div>

            <AnimatePresence>
                <motion.nav
                    className="nav"
                    initial={{ height: 0 }}
                    animate={{ height: isOpen ? "60px" : 0 }}
                    exit={{ height: 0 }}
                    ref={burgerContainer}
                >
                    <Link to="/" onClick={() => { setIsOpen(!isOpen); toggleNavTimeline(); toggleStarTimeline(); toggleTimeline()}} >
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isOpen ? 1 : 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 0.25 }}
                            className={`${location.pathname === "/" ? "active" : ""}`}
                            >
                            Home
                        </motion.p>
                    </Link>

                    <Link to="/news" onClick={() => { setIsOpen(!isOpen); toggleNavTimeline(); toggleStarTimeline(); toggleTimeline()}}>
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: isOpen ? 1 : 0 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 0.25 }}
                            className={`${location.pathname === "/news" ? "active" : ""}`}
                        >
                            News
                        </motion.p>
                    </Link>
                </motion.nav>
            </AnimatePresence>
        </>
    );
};

export default Nav;
