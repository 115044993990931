import React, { useRef, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import "./Intro.scss";
import Marquee from "react-fast-marquee";
import useScrollY from "../hooks/useScrollY";
import useIsMobile from "../hooks/useIsMobile";
import useIsTablet from "../hooks/useIsTablet";
import Arrow from "./arrow";
import useOutOfViewOnce from "../hooks/useOutOfViewViewOnce";
import useDelayedActive from "../hooks/useDelayedActive";

const slotIds = ["slot1", "slot2", "slot3", "slot4"];

interface FallingRectanglesProps {
    setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const FallingRectangles: React.FC<FallingRectanglesProps> = ({
    setIsComplete,
}) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const [constraints, setConstraints] = useState({});
    const rectangleRefs = useRef<Array<HTMLDivElement | null>>([]); // Refs for each rectangle
    const isTouchDevice =
        "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const [lockedPositions, setLockedPositions] = useState<
        { x: number; y: number }[]
    >(Array(4).fill({ x: 0, y: 0 }));
    const [lockedRectangles, setLockedRectangles] = useState(
        Array(4).fill(false)
    );
    const [draggedOne, setDraggedOne] = useState(false);
    const [draggedTwo, setDraggedTwo] = useState(false);
    const [draggedThree, setDraggedThree] = useState(false);
    const [draggedFour, setDraggedFour] = useState(false);
    const hasGoneOutOfView = useOutOfViewOnce(parentRef);
    const hoverEnabled1 = useDelayedActive(lockedRectangles[1], 1000);
    const hoverEnabled2 = useDelayedActive(lockedRectangles[2], 1000);
    const hoverEnabled3 = useDelayedActive(lockedRectangles[3], 1000);
    type DebounceFunction = (...args: any[]) => void;

    const debounce = (
        func: DebounceFunction,
        delay: number
    ): DebounceFunction => {
        let timeout: NodeJS.Timeout | null = null;
        return (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const [currentIndex, setCurrentIndex] = useState<number>();
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    

    useEffect(() => {
        if (hasGoneOutOfView) {
            setIsComplete(true);
            for (let i = 0; i < slotIds.length; i++) {
                handleDragEnd(null, { point: { x: 0, y: 0 } }, i);
            }
        }
        if (parentRef.current) {
            const parent = parentRef.current.getBoundingClientRect();
            setConstraints({
                top: -parent.height,
                bottom: parent.height,
                left: -parent.width / 2,
                right: parent.width / 2,
            });
        }
    }, [hasGoneOutOfView]);

    const handleDragEnd = (
        event: MouseEvent | TouchEvent | PointerEvent | null,
        info: { point: { x: number; y: number } },
        index: number
    ) => {
        const { point } = info;
        const targetSlotId = slotIds[index];
        const targetSlot = document.getElementById(targetSlotId);
        if (targetSlot) {
            console.log("targetSlot", targetSlot);
            const slotPosition = targetSlot.getBoundingClientRect();
            const parentPosition = parentRef.current?.getBoundingClientRect();
            console.log(hasGoneOutOfView);
            if (
                (Math.abs(
                    slotPosition.left + slotPosition.width / 2 - point.x
                ) <
                    slotPosition.width / 2 &&
                    Math.abs(
                        slotPosition.top + slotPosition.height / 2 - point.y
                    ) <
                        slotPosition.height / 2) ||
                hasGoneOutOfView
            ) {
                setLockedPositions((prev) => {
                    const updatedPositions = [...prev];
                    updatedPositions[index] = {
                        x: slotPosition.left - (parentPosition?.left ?? 0),
                        y: slotPosition.top - (parentPosition?.top ?? 0),
                    };
                    return updatedPositions;
                });
                setLockedRectangles((prev) => {
                    const updated = [...prev];
                    updated[index] = true;
                    return updated;
                });
            }
        }
    };

    return (
        <div ref={parentRef} className="falling-rectangles">
            <motion.div
                drag={!lockedRectangles[0]}
                dragConstraints={constraints}
                ref={(el) => (rectangleRefs.current[0] = el)}
                dragElastic={1}
                onDragEnd={(event, info) => {
                    handleDragEnd(event, info, 0);
                    setCurrentIndex(undefined);
                }}
                initial={{ y: -1000, opacity: 0, x: -150, rotate: "-10deg" }}
                animate={{
                    y: lockedRectangles[0] ? 0 : isMobile ? 20 : -10,
                    x: lockedRectangles[0]
                        ? isMobile
                            ? -25
                            : isTablet
                            ? -50
                            : -100
                        : -150,
                    top: lockedRectangles[0]
                        ? lockedPositions[0].y
                        : lockedPositions[0].y,
                    position: lockedRectangles[0] ? "absolute" : "relative",
                    opacity: 1,
                    rotate: `${
                        draggedOne || hasGoneOutOfView
                            ? "0deg"
                            : lockedRectangles[0]
                            ? "0deg"
                            : "-8deg"
                    }`,
                }}
                transition={{
                    delay: draggedOne ? 0 : 0.45,
                    duration: draggedOne ? 0 : 0.35,
                    type: "spring",
                    stiffness: 50,
                    damping: 15,
                }}
                className={`rectangle ${lockedRectangles[0] ? "active" : ""}`}
                onDragStart={() => {
                    setCurrentIndex(0);
                    setDraggedOne(true);
                }}
                onHoverStart={() => setCurrentIndex(0)}
                onHoverEnd={() => setCurrentIndex(undefined)}
            >
                {lockedRectangles[0] ? (
                    <>
                        <motion.h2
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                        >
                            We are building a new neighbourhood
                        </motion.h2>
                    </>
                ) : (
                    <div className="arrow">
                        <Arrow />
                        <p>Drag Me</p>
                    </div>
                )}
            </motion.div>
            <motion.div
                drag={!lockedRectangles[1]}
                dragConstraints={constraints}
                ref={(el) => (rectangleRefs.current[1] = el)}
                dragElastic={1}
                onDragStart={() => {
                    setCurrentIndex(1);
                    setDraggedTwo(true);
                }}
                onDragEnd={(event, info) => {
                    handleDragEnd(event, info, 1);
                    setCurrentIndex(undefined);
                }}
                initial={{ y: -1000, opacity: 0, x: 150, rotate: "-21deg" }}
                animate={{
                    opacity: 1,
                    y: lockedRectangles[1] ? 0 : -40,
                    x: lockedRectangles[1]
                        ? isMobile
                            ? 25
                            : isTablet
                            ? 50
                            : 100
                        : 150,
                    top: lockedRectangles[1] ? lockedPositions[1].y : 0,
                    position: lockedRectangles[1] ? "absolute" : "relative",
                    rotate: `${
                        draggedTwo || hasGoneOutOfView ? "0deg" : "-4deg"
                    }`,
                }}
                transition={{
                    delay: draggedTwo ? 0 : 0.3,
                    duration: draggedTwo ? 0 : 0.35,
                    type: "spring",
                    stiffness: 50,
                    damping: 15,
                }}
                className={`rectangle ${lockedRectangles[1] ? "active" : ""} ${
                    hoverEnabled1 ? "hoverEnabled" : ""
                }`}
                onHoverStart={() => setCurrentIndex(1)}
                onHoverEnd={() => setCurrentIndex(undefined)}
            >
                {lockedRectangles[1] && (
                    <>
                        <motion.h2
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                        >
                            for you to live in&nbsp;
                            <span className="underline">style</span>,
                        </motion.h2>
                        <Marquee
                            className={`marquee ${
                                hoverEnabled1 ? "hoverEnabled" : ""
                            }`}
                            autoFill
                            speed={ isMobile ? 100 : 150}
                        >
                            {" "}
                            <h3>
                                1-2-3 bed apartments, surrounded by lush nature
                                and urban convenience
                            </h3>
                            <img src="../ui/asterisk.svg" />
                        </Marquee>
                    </>
                )}
            </motion.div>
            <motion.div
                drag={!lockedRectangles[2]}
                dragConstraints={constraints}
                dragElastic={0.3}
                ref={(el) => (rectangleRefs.current[2] = el)}
                onDragStart={() => {
                    setCurrentIndex(2);
                    setDraggedThree(true);
                }}
                onDragEnd={(event, info) => {
                    handleDragEnd(event, info, 2);
                    setCurrentIndex(undefined);
                }}
                initial={{ y: -1000, opacity: 0, x: -150, rotate: "10deg" }}
                animate={{
                    y: lockedRectangles[2] ? 0 : 10,
                    x: lockedRectangles[2]
                        ? isMobile
                            ? -25
                            : isTablet
                            ? -50
                            : -100
                        : -150,
                    top: lockedRectangles[2] ? lockedPositions[2].y : 0,
                    position: lockedRectangles[2] ? "absolute" : "relative",
                    opacity: 1,
                    rotate: `${
                        draggedThree || hasGoneOutOfView ? "0deg" : "-10deg"
                    }`,
                }}
                transition={{
                    delay: draggedThree ? 0 : 0.15,
                    duration: draggedThree ? 0 : 0.35,
                    type: "spring",
                    stiffness: 50,
                    damping: 15,
                }}
                className={`rectangle ${lockedRectangles[2] ? "active" : ""} ${
                    hoverEnabled2 ? "hoverEnabled" : ""
                }`}
                onHoverStart={() => setCurrentIndex(2)}
                onHoverEnd={() => setCurrentIndex(undefined)}
            >
                {lockedRectangles[2] && (
                    <>
                        <Marquee
                            className={`marquee ${
                                hoverEnabled2 ? "hoverEnabled" : ""
                            }`}
                            autoFill
                            speed={ isMobile ? 100 : 150}
                        >
                            <h3>
                                110,000 sq ft of exceptional office spaces, in a
                                place where business thrives
                            </h3>
                            <img src="../ui/asterisk.svg" />
                        </Marquee>

                        <motion.h2
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                        >
                            do your best&nbsp;
                            <span className="underline">work</span>,
                        </motion.h2>
                    </>
                )}
            </motion.div>
            <motion.div
                drag={!lockedRectangles[3]}
                dragConstraints={constraints}
                dragElastic={0.3}
                ref={(el) => (rectangleRefs.current[3] = el)}
                onDragStart={() => {
                    setDraggedFour(true);
                    setCurrentIndex(3);
                }}
                onDragEnd={(event, info) => {
                    handleDragEnd(event, info, 3);
                    setCurrentIndex(undefined);
                    setIsComplete(true);
                }}
                initial={{ y: -1000, opacity: 0, x: 150 }}
                animate={{
                    y: lockedRectangles[3] ? 0 : -20,
                    x: lockedRectangles[3]
                        ? isMobile
                            ? 25
                            : isTablet
                            ? 50
                            : 100
                        : 150,
                    top: lockedRectangles[3] ? lockedPositions[3].y : 0,
                    position: lockedRectangles[3] ? "absolute" : "relative",
                    opacity: 1,
                    rotate: `${
                        draggedFour || hasGoneOutOfView ? "0deg" : "-4deg"
                    }`,
                }}
                transition={{
                    delay: 0,
                    duration: draggedFour ? 0 : 0.35,
                    type: "spring",
                    stiffness: 50,
                    damping: 15,
                }}
                className={`rectangle ${lockedRectangles[3] ? "active" : ""} ${
                    hoverEnabled3 ? "hoverEnabled" : ""
                }`}
                onHoverStart={() => setCurrentIndex(3)}
                onHoverEnd={() => setCurrentIndex(undefined)}
            >
                {lockedRectangles[3] && (
                    <>
                        <Marquee
                            className={`marquee ${
                                hoverEnabled3 ? "hoverEnabled" : ""
                            }`}
                            autoFill
                            speed={ isMobile ? 100 : 150}
                        >
                            <img src="../ui/asterisk.svg" />
                            <h3>
                                A welcoming neighborhood for all, with a public
                                park to explore
                            </h3>
                        </Marquee>

                        <>
                            <motion.h2
                                initial={{ opacity: 0 }}
                                whileInView={{ opacity: 1 }}
                            >
                                or just&nbsp;
                                <span className="underline">hang out</span>
                            </motion.h2>
                        </>
                    </>
                )}
            </motion.div>

            <div className="slots">
                <div
                    className={`slot ${currentIndex === 0 ? "white" : ""} ${lockedRectangles[0] ? "active" : ""}`}
                    id="slot1"
                ></div>
                <div
                    className={`slot ${currentIndex === 1 ? "white" : ""} ${lockedRectangles[1] ? "active" : ""}`}
                    id="slot2"
                ></div>
                <div
                    className={`slot ${currentIndex === 2 ? "white" : ""} ${lockedRectangles[2] ? "active" : ""}`}
                    id="slot3"
                ></div>
                <div
                    className={`slot ${currentIndex === 3 ? "white" : ""} ${lockedRectangles[3] ? "active" : ""}`}
                    id="slot4"
                ></div>
            </div>
        </div>
    );
};

const Intro = () => {
    const scroll = useScrollY();
    const [isComplete, setIsComplete] = useState(false);
    return (
        <div className="intro-anim">
            <h1 className="animateIn">HEY CAMBRIDGE</h1>
            <FallingRectangles setIsComplete={setIsComplete} />
            <div className="asterisk">
                <motion.p
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: isComplete ? 1 : 0,
                        x: isComplete ? 0 : 20,
                    }}
                >
                    Coming soon
                </motion.p>
                <img
                    style={{ transform: `rotate(-${scroll / 2}deg)` }}
                    src="../ui/asterisk.svg"
                />
                <motion.p
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: isComplete ? 1 : 0,
                        x: isComplete ? 0 : -20,
                    }}
                >
                    check back in a bit
                </motion.p>
            </div>
        </div>
    );
};

export default Intro;
