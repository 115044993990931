import useScrollY from "../../hooks/useScrollY";

const Logo = () => {
    const scrollY = useScrollY();

    return (
        <div className="logo-wrapper">
            <div className="logo">
                <img src="../images/mill.svg" />
                <img
                    style={{ transform: `rotate(${scrollY / 2}deg)` }}
                    src="../ui/asterisk.svg"
                />
                <img src="../images/yard.svg" />
            </div>
        </div>
    );
};

export default Logo;
