export function formatDateWithOrdinal(dateString: string): string {
    const date = new Date(dateString);
    
    const day: number = date.getDate();
    const month: string = date.toLocaleString('en-GB', { month: 'long' });
    const year: number = date.getFullYear();
        
    const getOrdinal = (day: number): string => {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${getOrdinal(day)} ${month} ${year}`;
}              