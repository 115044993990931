import { useEffect, useState } from 'react';

function useDelayedActive(trigger: boolean, delay: number): boolean {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (trigger) {
      timer = setTimeout(() => setIsActive(true), delay);
    } else {
      setIsActive(false);
    }

    return () => clearTimeout(timer);
  }, [trigger, delay]);

  return isActive;
}

export default useDelayedActive;
