import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Article } from "../../types/article";
import "./newsArticle.scss";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { formatDateWithOrdinal } from "../../helpers/helpers";

const NewsArticle = () => {
    const { slug } = useParams();
    const [article, setArticle] = useState<Article | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    gsap.registerPlugin(ScrollTrigger);    
    
    
    useEffect(() => {
        const elements = document.querySelectorAll(".animateIn");
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                {                 
                    opacity: 0,
                    y: 100,                 
                },
                {
                    opacity: 1,
                    y: 0,                    
                    scrollTrigger: {
                        trigger: element,
                        start: "top 100%",
                        end: "bottom 20%",
                        toggleActions: "play none none reverse", 
                    },
                }
            );
        });
    });

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(
                    `https://wp.sirendigital.uk/mill-yard/wp-json/wp/v2/posts?_embed&slug=${slug}`
                );
                if (response.data.length > 0) {
                    setArticle(response.data[0]);
                } else {
                    setError("Article not found");
                }
            } catch (err) {
                setError("Failed to load article");
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();
    }, [slug]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <article>
            {article && (
                <>
                <div className="contain-wrapper">
                    <div
                        className="background-image contain animateIn"
                        style={{
                            backgroundImage: `url(${article._embedded["wp:featuredmedia"][0].source_url})`,
                        }}
                    ></div>

                    <div className="content-wrapper contain">
                        <div className="title animateIn">
                            <h2>{article.title.rendered}</h2>
                        </div>
                        <div className="content">
                            <p
                                className="copy-wrapper animateIn"
                                dangerouslySetInnerHTML={{
                                    __html: article.content.rendered,
                                }}
                            />
                            <p className="animateIn">{formatDateWithOrdinal(article.date)}</p>
                            <Link className="return-to-all animateIn" to={`/news`}>
                                <div>
                                    <p>Return to all</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    </div>
                </>
            )}
        </article>
    );
};

export default NewsArticle;
